<template>
    <div>
        <b-container>
            <b-row>
                <b-col>
                    <strong>Registration for {{user.userLabel}}</strong>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <br />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <template v-if="!!sessionsForUser && sessionsForUser.length > 0">
                        <b-table small striped hover bordered
                                 stacked="sm"
                                 head-variant="dark"
                                 :items="sessionsForUser"
                                 :fields="sessionFields">
                            <template v-slot:cell(label)="row">
                                <b-link
                                        @click="getDetails(row.item.session)">{{row.item.session.availableSeatsLabel}}</b-link>
                            </template>
                            <template v-slot:cell(track)="row">
                                {{getTrackTitle(row.item.session.trackId)}}
                            </template>
                            <template v-slot:cell(button)="row">
                                <b-dropdown
                                        :variant="statusVariant(row.item.status)"
                                        :text="statusText(row.item)"
                                        size="sm">
                                        <b-dropdown-item
                                                href="#"
                                                @click="register(row.item)">Register</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                    </template>
                    <!-- Only show if sessions are loaded but there are none -->
                    <template v-if="noSessions">
                        No registrable sessions found, check back later.
                        <br /><br />
                        <b-link @click="showRules">Why can't I register?</b-link>
                    </template>
                </b-col>
            </b-row>
        </b-container>
        <!--RULES MODAL-->
        <b-modal id="registration-rules-modal"
                 size="xl"
                 scrollable
                 ok-only
                 button-size="sm"
                 title="Registration Rules"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <registration-rules />
        </b-modal>
        <!-- CONFIRM REGISTRATION MODAL -->
        <b-modal :id="registrationModalId"
                 size="xl"
                 scrollable
                 noCloseOnBackdrop
                 noCloseOnEsc
                 hideHeaderClose
                 hideFooter
                 title="Confirm Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <ConfirmRegistration
                    :fromTravel="fromTravel"
                    :registrationRequest="registrationRequest"
                    :requestType="requestType"
                    @registered="registered"
                    @canceled="canceled" />
        </b-modal>
        <!-- SESSION DETAILS MODAL -->
        <b-modal :id="sessionDetailsModalId"
                 size="xl"
                 scrollable
                 ok-only
                 title="Session Details"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <SessionDetails
                    :session="session" />
        </b-modal>
    </div>
</template>

<script>
import { Component, Vue} from 'vue-property-decorator';
import {Trainee, Mentor, User, NULL_USER} from "@/model/user";
import {NULL_TRACK} from "@/model/track";
import _ from "underscore";
import store from '@/store/store';
import {sprintf} from 'sprintf-js';
import {RegistrationRequest, RegistrationRequestType} from "@/model/registration";
import RegistrationRules from '@/views/private/users/RegistrationRules.vue';
import SessionDetails from '@/views/private/tracks/SessionDetails.vue';
import ConfirmRegistration from "@/views/private/tracks/ConfirmRegistration.vue";
import {Status, NEW_SESSION_REGISTRATION} from "@/model/registration";
import {errorToastOptions} from "../../../util/formatters";
import covidRegistrationRestriction from '@/errors/covid_registration_restriction';;

@Component({
    props: {
        user: [Trainee, Mentor, User],
        fromTravel: {
            type: Boolean,
            default: false
        }
    },
    components: {
        RegistrationRules,
        ConfirmRegistration,
        SessionDetails
    },
    asyncComputed: {
        sessionsForUser: {
            async get() {
                if (!User.isValidUser(this.user)) {
                    return [];
                }
                //Refresh sessions (no need to refresh user)
                try {
                    await store.dispatch('registration/loadRegistrableSessionsByUser', this.user);
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                const sessions = store.getters['registration/registrableSessionsByUser'](this.user);
                const scheduleItems = _.map(sessions, session => {
                    return new RegistrationRequest(this.user, session, NEW_SESSION_REGISTRATION);
                });
                return scheduleItems;
            }
        }
    },
    methods: {
        refreshSessions() {
            this.$asyncComputed.sessionsForUser.update();
        }
    }
})
export default class UserRegistration extends Vue {
    registrationRequest = null;
    session = null;

    get noSessions() {
        const loaded = store.getters['registration/registrableSessionsLoadedByUser'](this.user);
        const hasSessions = !!this.sessionsForUser && this.sessionsForUser.length > 0;
        return !!loaded && !hasSessions;
    }

    registered(details) {
        this.refreshSessions();
        this.registrationRequest = null;
        this.$bvModal.hide(this.registrationModalId);
        // console.log(details);
        this.$emit('done', details);
    }

    canceled() {
        this.$bvModal.hide(this.registrationModalId);
        this.registrationRequest = null;
    }

    get requestType() {
        return RegistrationRequestType.SESSION_REGISTRATION;
    }

    statusVariant(status) {
        return Status.variant(status);
    }

    statusText(scheduleItem) {
        if (_.isEqual(scheduleItem.status, Status.SCHEDULED) && !_.isUndefined(scheduleItem.user.trackId) &&
            scheduleItem.user.trackId !== scheduleItem.session.trackId) {
            return 'NOT SCHEDULED';
        }
        else {
            return scheduleItem.status;
        }
    }

    get registrationModalId() {
        return sprintf('confirm-registration-modal-%d', this.user.id);
    }

    get sessionDetailsModalId() {
        if (_.isNull(this.session)) {
            return 'session-details-modal-invalid';
        }
        else {
            return sprintf('session-details-modal-%d', this.session.sessionId);
        }
    }

    async beforeCreate() {
        try {
            await store.dispatch('tracks/loadTracks');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    getTrackTitle(id) {
        const track = this.$store.getters['tracks/getTrackById'](id);
        if (_.isNull(track) || _.isUndefined(track) || _.isEqual(track, NULL_TRACK)) {
            return sprintf('Unknown (track ID %d)', id);
        }
        else {
            return track.title;
        }
    }

    mounted() {
        // console.log(this.user);
        this.refreshSessions();
    }

    showRules() {
        this.$bvModal.show('registration-rules-modal');
    }

    getDetails(session) {
        this.session = session;
        this.$bvModal.show(this.sessionDetailsModalId);
    }

    get sessionFields() {
        return [{
            key: 'label',
            label: 'Session',
            sortable: true
        }, {
            key: 'track',
            label: 'Track',
            sortable: true
        }, {
            key: 'button',
            label: 'Register',
            sortable: false
        }];
    }

    async register(scheduleItem) {
        //Check for override
        if (scheduleItem.needsOverride) {
            const activeUser = store.getters['userSession/getUser'];
            if (activeUser.isAnAdministrator()) {
                const check = await this.$bvModal.msgBoxConfirm('The participant you are attempting to ' +
                    'register for a session that requires vaccine verification has not been verified. Would you ' +
                    'like to override the vaccine requirement and register this participant anyway?', {
                    title: 'Vaccine Override Needed',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                });
                if (check) {
                    //Set override flag
                    scheduleItem.registration.vaccineOverride = true;
                } else {
                    return;
                }
            } else {
                //A non-admin cannot override
                return this.$bvModal.msgBoxOk(covidRegistrationRestriction(this), {
                    size: 'md',
                    buttonSize: 'sm'
                });
            }
        }
        //Other eligibility checking built-in to session selection
        this.registrationRequest = scheduleItem;
        this.$bvModal.show(this.registrationModalId);
    }
}
</script>

<style scoped>
</style>