<template>
    <b-card no-body>
        <template #header>
            <strong>Why can't I register?</strong>
        </template>
        <b-list-group flush>
            <b-list-group-item v-for="rule in rules" :key="rule">{{rule}}</b-list-group-item>
        </b-list-group>
    </b-card>
</template>
<script>
import {Vue, Component} from 'vue-property-decorator';

@Component
export default class RegistrationRules extends Vue {
    get rules() {
        return [
            'Registration must be open to register for a session. Only CITF Administration can open registration.',
            'The session must have available seats to register. If there are no available seats, please contact CITF Administration.',
            'If the session has a COVID-19 registration restriction, you cannot register a participant unless they are fully vaccinated for COVID-19. UBC members must contact their Local Training Center to verify their vaccine card and add the COVID-19 Vaccine Verification Qual/Cert in the TRAIN system. Non-UBC Members: Please contact CITF Administration.',
            'Mentors can only register themselves and their trainee(s).',
            'A Mentor can only register themselves for the corresponding Program 3 session that their Trainee is scheduled to attend.',
            'A Mentor cannot register for sessions out of order. Registrations must be completed in sequential order, such as Program 1, Program 2, Program 3, and then Program 4.',
            'If your trainee is unable to attend a scheduled Program 2 or Program 3 session, you may register for an upcoming session of the same program.',
            'To register for a Program 1 or Program 4 session, which you are not scheduled to attend, please contact CITF Administration.',
            'If airfare has already been booked, you will not be able to cancel a registration.'
        ];
    }
}
</script>
<style scoped>

</style>
